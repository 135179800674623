<template>
    <div class="content">
        <section class="info">
            <router-link
                :to="getProductLink()"
                :title="getTitleName(content.products[0])"
                class="picture d-block d-sm-none">
                <img :src="content.products[0].picture" :alt="getTitleName(content.products[0])" :title="getTitleName(content.products[0])" @error="helper.setNoImg($event, 'product')" />
            </router-link>
            <router-link 
                :to="getProductLink()"
                :title="getTitleName(content.products[0])"
                class="product">
                <img :src="content.products[0].brandLogo" :alt="content.products[0].brand" :title="content.products[0].brand" />
                <p>{{ content.products[0].brand + ' ' + content.products[0].name }}<span v-for="(product, index) in content.products" :key="index">{{ product.size + ' ปี ' + product.year }}</span></p>
            </router-link>

            <div class="by">
                <span>รีวิวเมื่อ {{ content.reviewDate }}</span>
                <p>โดย {{ content.customer }}</p>
            </div>

            <div class="rating">
                <div class="rate">
                    <label>บริการของร้านยาง</label>
                    <div class="stars">
                        <b-icon icon="star-fill"
                            class="icon"
                            :class="{ 'active': (index + 1) <= content.ratingStore }"
                            v-for="(star, index) in 5"
                            :key="index">
                        </b-icon>
                        
                    </div>
                </div>
                <div class="rate">
                    <label>คุณภาพสินค้า</label>
                    <div class="stars">
                        <b-icon icon="star-fill"
                            class="icon"
                            :class="{ 'active': (index + 1) <= content.ratingProduct }"
                            v-for="(star, index) in 5"
                            :key="index">
                        </b-icon>
                    </div>
                </div>
                <div class="rate">
                    <label>การใช้งานเยลโลไทร์</label>
                    <div class="stars">
                        <b-icon icon="star-fill"
                            class="icon"
                            :class="{ 'active': (index + 1) <= content.ratingYellowTire }"
                            v-for="(star, index) in 5"
                            :key="index">
                        </b-icon>
                    </div>
                </div>
            </div>
        </section>
        <router-link
            :to="getProductLink()"
            :title="getTitleName(content.products[0])"
            class="picture d-none d-sm-block">
            <img :src="content.products[0].picture" :alt="content.products[0].name" :title="content.products[0].name" @error="helper.setNoImg($event, 'product')" />
        </router-link>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import RouterPath from '@/router/path';

export default {
    data() {
        return {
            helper: Helper
        };
    },
    props: {
        content: Object
    },
    methods: {
        getProductLink() {
            return Helper.replaceRouteParam(RouterPath.TIRE_DETAIL, ':id', this.content.products[0].param);
        },
        getTitleName(product) {
            return product.brand + ' ' + product.name + ' ' + product.size + ' ปี ' + product.year;
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.content {
    display: flex;   

    a {
        color: #000;
    }

    .info {
        flex: 1;

        .picture {
            position: relative;
            overflow: hidden;
            display: block;
            width: 200px;
            height: 100px;
            margin: 0 auto 10px;

            img {
                position: absolute;
                width: 100%;

            }
        }

        .product {
            display: block;
            margin-bottom: 5px;

            @media only screen and (max-width: $screenExtraSmall) {
                text-align: center;
            }

            img {
                height: 30px;
                border: 1px solid #ccc;
                margin-bottom: 5px;
            }

            p, span {
                font-size: 16px;
                line-height: 22px;
                font-family: 'Open Sans', 'Kanit';

                & + span::before {
                    content: " + ";
                }
            }
        }

        .by {
            span {
                display: block;
                font-size: 11px;
                color: #999;
            }

            p {
                display: block;
                font-size: 13px;
                line-height: 15px;
            }
        }

        .rating {
            .rate {
                display: flex;
                align-items: center;

                label {
                    margin: 0;
                    font-size: 13px;
                    width: 130px;
                    color: #666;
                }

                .stars {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon {
                        color: #bbb;
                        margin: 2px 3px;
                        font-size: 18px;
                        cursor: pointer;

                        &.active {
                            color: #ffaa00;
                        }
                    }
                }
            }
        }
    }

    .picture {
        width: 140px;
        padding-top: 10px;

        img {
            display: block;
            width: 100%;
        }
    }
}
</style>