<template>
    <router-link
        :to="routerPath.STORE + '/' + content.store.param"
        :title="content.store.name"
        class="store">
        <img :src="content.store.picture" :alt="content.store.name" :title="content.store.name" @error="helper.setNoImg($event, 'store')" />
        <div class="data">
            <span><b-icon icon="gear"></b-icon> ใช้บริการกับ</span>
            <p>{{ content.store.name }}</p>
        </div>
    </router-link>
</template>

<script>
import Helper from '@/services/helper';
import RouterPath from '@/router/path';

export default {
    data() {
        return {
            helper: Helper,
            routerPath: RouterPath
        };
    },
    props: {
        content: Object
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.store {
    margin-top: 15px;
    display: flex;
    align-items: center;
    background: #f5f5f5;
    color: #333;
    
    img {
        display: block;
        width: 130px;
    }

    .data {
        flex: 1;
        padding-left: 20px;
        padding-right: 20px;

        span {
            display: block;
            color: #666;
            font-size: 13px;
        }

        p {
            font-size: 18px;
            line-height: 20px;
            font-family: 'Open Sans', 'Kanit';
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
        }
    }
}
</style>