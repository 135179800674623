<template>
    <section class="reviews">
        <div class="sliders">
            <div class="item" v-for="(item, index) in reviewContents" :key="index">
                <review-card
                    :content="item">
                </review-card>
            </div>
        </div>
    </section>
</template>

<script>
import ReviewCard from '@/components/home/ReviewCard';

export default {
    components: {
        ReviewCard
    },
    props: {
        reviewContents: Array
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.reviews {
    .sliders {
        display: flex;
        margin: 0 -5px;

        .item {
            width: 25%;
            padding: 0 5px;

            @media only screen and (max-width: $screenMedium) {
                width: 50%;
            }

            @media only screen and (max-width: $screenSmall) {
                width: 100%;
            }
        }
    }
}
</style>