<template>
    <div class="content">
        <router-link
            :to="getProductLink()"
            :title="getTitleName(content.products[0])"
            class="picture">
            <img :src="content.products[0].picture" :alt="getTitleName(content.products[0])" :title="getTitleName(content.products[0])" @error="helper.setNoImg($event, 'product')" />
        </router-link>
        <router-link 
            :to="getProductLink()"
            :title="getTitleName(content.products[0])"
            class="product">
            <img :src="content.products[0].brandLogo" :alt="content.products[0].brand" :title="content.products[0].brand" />
            <p>{{ content.products[0].brand + ' ' + content.products[0].name }} <span v-for="(product, index) in content.products" :key="index">{{ product.size + ' ปี ' + product.year }}</span></p>
        </router-link>

        <div class="by">
            <span>รีวิวเมื่อ {{ content.reviewDate }}</span>
            <p>โดย {{ content.customer }}</p>
        </div>

        <div class="rating">
            <div class="rate">
                <label>บริการของร้านยาง</label>
                <div class="stars">
                    <b-icon icon="star-fill"
                        class="icon"
                        :class="{ 'active': (index + 1) <= content.ratingStore }"
                        v-for="(star, index) in 5"
                        :key="index">
                    </b-icon>
                    
                </div>
            </div>
            <div class="rate">
                <label>คุณภาพสินค้า</label>
                <div class="stars">
                    <b-icon icon="star-fill"
                        class="icon"
                        :class="{ 'active': (index + 1) <= content.ratingProduct }"
                        v-for="(star, index) in 5"
                        :key="index">
                    </b-icon>
                </div>
            </div>
            <div class="rate">
                <label>การใช้งานเยลโลไทร์</label>
                <div class="stars">
                    <b-icon icon="star-fill"
                        class="icon"
                        :class="{ 'active': (index + 1) <= content.ratingYellowTire }"
                        v-for="(star, index) in 5"
                        :key="index">
                    </b-icon>
                </div>
            </div>
        </div>

        <article ref="refArticle">
            {{ content.comment }}

            <button v-if="isOverflowing" @click="openReviewModal()">อ่านต่อ</button>
        </article>
            
        <router-link
            :to="routerPath.STORE + '/' + content.store.param"
            :title="content.store.name"
            class="store">
            <img :src="content.store.picture" :alt="content.store.name" :title="content.store.name" @error="helper.setNoImg($event, 'store')" />
            <div class="data">
                <span><b-icon icon="gear"></b-icon> ใช้บริการกับ</span>
                <p>{{ content.store.name }}</p>
            </div>
        </router-link>

        <modal-review
            @close="closeReviewModal()"
            :content="content"
            :isDisplay="isOpenReview">
        </modal-review>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import ModalReview from '@/components/review/ModalReview.vue';

export default {
    components: {
        ModalReview,
    },
	data() {
        return {
            helper: Helper,
            routerPath: RouterPath,
            isOpenReview: false,
            isOverflowing: false
        }
    },
    props: {
        content: Object
    },
    mounted() {
        this.$nextTick(() => {
            this.checkArticleOverflow();
        });
    },
    methods: {
        checkArticleOverflow() {
            const article = this.$refs.refArticle;

            if (article) {
                this.isOverflowing = article.scrollHeight > article.clientHeight;
            }
        },
        getProductLink() {
            return Helper.replaceRouteParam(RouterPath.TIRE_DETAIL, ':id', this.content.products[0].param);
        },
        getTitleName(product) {
            return product.brand + ' ' + product.name + ' ' + product.size + ' ปี ' + product.year;
        },
        openReviewModal() {
            this.isOpenReview = true;
        },
        closeReviewModal() {
            this.isOpenReview = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.content {
    background: #fff;
    padding: 10px;

    a {
        color: #000;
    }

    .picture {
        position: relative;
        overflow: hidden;
        display: block;
        width: 200px;
        height: 80px;
        margin: 0 auto 10px;

        img {
            position: absolute;
            top: -15px;
            width: 100%;
        }
    }

    .product {
        display: block;
        margin-bottom: 5px;
        text-align: center;

        img {
            height: 30px;
            border: 1px solid #ccc;
            margin-bottom: 5px;
        }

        p {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 40px;
        }

        p, span {
            font-size: 15px;
            line-height: 20px;
            font-family: 'Open Sans', 'Kanit';
            margin: 0;

            & + span::before {
                content: " + ";
            }
        }
    }

    .by {
        text-align: center;

        span {
            display: block;
            font-size: 11px;
            color: #999;
        }

        p {
            display: block;
            font-size: 13px;
            line-height: 15px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .rating {
        .rate {
            display: flex;
            align-items: center;

            label {
                margin: 0;
                font-size: 12px;
                width: 125px;
                color: #999;
            }

            .stars {
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    color: #bbb;
                    margin: 1px 2px;
                    font-size: 16px;
                    cursor: pointer;

                    &.active {
                        color: #ffaa00;
                    }
                }
            }
        }
    }

    article {
        position: relative;
        margin-top: 12px;
        font-size: 13px;
        line-height: 21px;
        height: 42px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 55px;

        button {
            position: absolute;
            bottom: 0;
            right: 0;
            background: #fff;
            border: none;
            outline: none;
            font-weight: bold;
            font-size: 13px;
            line-height: 18px;
            color: #0d6efd;
            text-decoration: underline;

            &:hover {
                opacity: 1;
            }
        }
    }

    .store {
        margin-top: 15px;
        display: flex;
        align-items: center;
        background: #f5f5f5;
        color: #333;
        
        img {
            display: block;
            width: 80px;
        }

        .data {
            flex: 1;
            padding-left: 16px;
            padding-right: 16px;

            span {
                display: block;
                color: #666;
                font-size: 11px;
            }

            p {
                font-size: 13px;
                line-height: 15px;
                font-weight: bold;
                margin: 0;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>