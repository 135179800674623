<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-review modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>รีวิวจากผู้ใช้บริการ</span>
                        </h4>

                        <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <rating-info :content="content" class="mb-3"></rating-info>

                        <article>
                            {{ content.comment }}
                        </article>
                            
                        <store-display :content="content"></store-display>

                        <div class="operation">
                            <button class="btn btn-sm btn-main" @click="hide()">ปิดหน้าต่าง</button>
                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import RatingInfo from '@/components/review/RatingInfo.vue';
import StoreDisplay from '@/components/review/StoreDisplay.vue';

export default {
    components: {
        RatingInfo,
        StoreDisplay
    },
    data() {
		return {
            helper: Helper,
            routerPath: RouterPath,
            isActive: false
		};
	},
	props: {
		isDisplay: Boolean,
        content: Object
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide() {
            this.$emit('close');
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-review {
    max-width: 600px;
    
    .btn-close {
        z-index: 1;
    }

    article {
        position: relative;
        font-size: 13px;
        line-height: 21px;
    }

    .operation {
        margin-top: 30px;

        .btn {
            font-size: 13px;
            width: 100%;
            padding: 10px 16px;
        }
    }
}
</style>