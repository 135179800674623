<template>
    <section class="home-network">
        <marquee-text :repeat="5" :duration="30">
            <div class="wrapper">
                <router-link :to="routerPath.NETWORK + '/' + item.param" v-for="(item, index) in network" :key="index" :title="item.title">
                    <img :src="item.img" :alt="item.title" :title="item.title" />
                    <span>{{ item.title }}</span>
                </router-link>
            </div>
        </marquee-text>
    </section>
</template>

<script>
import RouterPath from '@/router/path';

export default {
    data() {
        return {
            routerPath: RouterPath
        };
    },
    props: {
        network: Array
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.home-network {
    .wrapper {
        overflow: hidden;
        display: flex;

        a {
            display: block;
            margin-right: 10px;
            width: 100px;

            @media only screen and (max-width: $screenLarge) {
                width: 75px;
            }

            img {
                display: block;
                width: 100%;
                border: 1px solid #333;
            }

            span {
                margin-top: 5px;
                font-size: 12px;
                line-height: 12px;
                color: #000;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;

                @media only screen and (max-width: $screenLarge) {
                    font-size: 11px;
                    line-height: 11px;
                }
            }
        }
    }
}
</style>